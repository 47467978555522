export enum Roles {
    IT_DEPT = "IT Department",
    MEDTECH_DEPT = "Medtech Department",
    PATHOLOGIST_DEPT = "Pathologist Department",
    SUPER_ADMINISTRATION_DEPT = "Super Administration Department",
    ADMINISTRATION_DEPT = "Administration Department",
    OPERATION_DEPT = "Operation Department",
    PROCUREMENT_DEPT = "Procurement Department",
    PHYSICIAN_DEPT = "Physician Department",
    RADIOLOGY_DEPT = "Radiology Department",
    ACCOUNTING_DEPT = "Accounting Department",
}

export enum UserCategories {
    USER = "User",
    MEDICAL_TECHNOLOGIST = "Medical Technologist",
    PATHOLOGIST = "Pathologist",
    PHYSICIAN = "Physician",
    RADIOLOGIST = "Radiologist",
    ACCOUNTING = "Accounting",
}

export enum InvoiceType {
    Normal = "Normal",
}

export enum PaymentType {
    CASH = "Cash",
    NOT_APPLICABLE = "Not Applicable",
}


export enum Gender {
    MALE = "Male",
    FEMALE = "Female"
}

export enum CivilStatus {
    SINGLE = "Single",
    MARRIED = "Married",
    WIDOW = "Widow",
    WIDOWER = "Widower",
    LIVEIN = "Livein",
    SEPERATED = "Seperated",
    DIVORSED = "Divorsed"
}

export enum TestCategories {
    SIMPLE = "Simple",
    BUNDLE = "Bundle"
}

export enum CompanyCategories {
    IN_HOUSE = "In House",
    THIRD_PARTY_COMPANY = "Third Party Company",
    HMO = "HMO"
}

export enum IncidentStatuses {
    OPEN = "Open",
    CLOSED = "Closed"
}

export enum Priorites {
    HIGH = "High",
    MEDIUM = "Medium",
    LOW = "Low"
}

export enum LaboratoryTypes {
    SEROLOGY = "SEROLOGY",
    BLOOD_CHEMISTRY = "BLOOD CHEMISTRY",
    HEMATOLOGY = "HEMATOLOGY",
    URINALYSIS = "URINALYSIS",
    FECALYSIS = "FECALYSIS",
    XRAY = "XRAY",
    ULTRASOUND = "ULTRASOUND",
    ECG = "ECG",
    ECHO = "2DECHO",
    PHYSICAL_EXAMINATION = "PHYSICAL EXAMINATION"
}

export enum ReportTypes {
    ALL_FEEDBACKS_REPORT = "All Feedbacks Report",
    OPEN_INCIDENTS_REPORT = "All Open Incidents Report",
    ALL_SALES_REPORT = "All Sales Report",
    ALL_STOCKS_REPORT = "All Stocks Report",
    BLOOD_CHEMISTRY_CENSUS_REPORT = "Blood Chemistry Census Report",
    BUNDLE_CENSUS_REPORT = "Bundle Census Report",
    BUNDLE_REPORT = "Bundle Tests Report",
    DASHBOARD_REPORT = "Dashboard Report",
    DISCOUNT_REPORT = "Discount Report",
    DISCOUNT_WITH_REFERRAL_REPORT = "Discount with Referral Report",
    FECALYSIS_CENSUS_REPORT = "Fecalysis Census Report",
    HEMATOLOGY_CENSUS_REPORT = "Hematology Census Report",
    INVOICES_REPORT = "Invoices Report",
    OR_REPORT = "OR Report",
    REFERRAL_CODE_REPORT = "Referral Census Report",
    REFERRAL_REPORT = "Referral Report",
    SALES_REPORT = "Sales Report",
    SEROLOGY_CENSUS_REPORT = "Serology Census Report",
    SIMPLE_REPORT = "Simple Tests Report",
    TESTS_REPORT = "Tests Report",
    TEST_RECOMMENDATION_REPORT = "Test Recommendation Report",
    URINALYSIS_CENSUS_REPORT = "Urinalysis Census Report",
    XRAY_CENSUS_REPORT = "XRAY Census Report",
    VOID_REPORT = "Void Report",
}

export enum ReportTypeProcurement {
    ALL_STOCKS_REPORT = "All Stocks Report",
    INVOICES_REPORT = "Invoices Report"
}

export enum SalesReportOnly {
    SALES_REPORT = "Sales Report"
}

export enum AllowEditLaboratoryTypes {
  SEROLOGY = 'SEROLOGY',
  BLOOD_CHEMISTRY = 'BLOOD CHEMISTRY',
  HEMATOLOGY = 'HEMATOLOGY',
  URINALYSIS = 'URINALYSIS',
  FECALYSIS = 'FECALYSIS',
  XRAY = 'XRAY',
}