import axios from 'axios';
import { getCookieValue } from './utils/Utils';


export const initAxiosDefaultHeaders = () => {
    const jwtToken = getCookieValue('jwt');
    window.axios = axios;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
}

initAxiosDefaultHeaders();

export const fetcher = (url: string) => axios.get(url).then(res => res.data);
export const poster = (url: string, { arg }: { arg: any }) => axios.post(url, arg).then(res => res.data);
export const posterFile = (url: string, { arg }: { arg: any }) => axios.post(url, arg, {
    headers: {
        "Content-Type": "multipart/form-data",
    }
}).then(res => res.data);
export const updater = (url: string, { arg }: { arg: any }) => axios.patch(url, arg).then(res => res.data);
export const deleter = (url: string, { arg }: { arg: any }) => axios.delete(url, arg).then(res => res.data);