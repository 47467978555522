import { SWRResponse } from 'swr';
import { useCreate, useDelete, useGetDetail, useGetListing, useUpdate } from './CommonService';

const resource = 'feedback';

export const useGetFeedbacks = (params?: string): SWRResponse => {
    return useGetListing(`${resource}/all`, params);
}

export const useGetFeedback = (id: string, params?: string): SWRResponse => {
    return useGetDetail(`${resource}`, id, params);
}

export const useCreateFeedback = (params?: string) => {
    return useCreate(`${resource}/add`, params);
}

export const useUpdateFeedback = (id: string, params?: string) => {
    return useUpdate(`${resource}/update`, params);
}