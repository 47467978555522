interface Strings {
    [key: string]: any;
};

export const config: Strings = {
    //MOCKUP
    // API_HOST: 'https://private-0879ba-laboratory1.apiary-mock.com/api/v1'

    //LOCALHOST
    // API_HOST: 'http://localhost:8080/api/v1'

    //PROD
    API_HOST: 'https://api.blescesdlms.com/api/v1'
}