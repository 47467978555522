import { SWRResponse } from 'swr';
import { useCreate, useDelete, useGetDetail, useGetListing, useUpdate } from './CommonService';

const resource = 'branch';

export const useGetBranches = (params?: string): SWRResponse => {
    return useGetListing(`${resource}/all`, params);
}

export const useGetBranch = (id: string, params?: string): SWRResponse => {
    return useGetDetail(`${resource}`, id, params);
}

export const useCreateBranch = (params?: string) => {
    return useCreate(`${resource}/add`, params);
}

export const useUpdateBranch = (id: string, params?: string) => {
    return useUpdate(`${resource}/update`, params);
}

export const useDeleteBranch = (id: string, params?: string) => {
    return useDelete(`${resource}/delete/${id}`, params);
}