import { atom } from "recoil";
import { User } from "../model";

interface ILoginState {
    user?: User;
};

const initialState: ILoginState = {
    user: {
        "id": 0,
        "firstName": "",
        "middleName": "",
        "lastName": "",
        "role": "",
        "category": "",
        "userName": "",
        "menuList": ""
    } // temp defined to bypass the login
}

const loginState = atom({
    key: 'loginState',
    default: initialState
});

export {
    loginState,
    initialState
};