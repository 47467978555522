import { SWRResponse } from 'swr';
import { useCreate, useDelete, useGetDetail, useGetListing, useUpdate } from './CommonService';

const resource = 'user';

export const useGetUsers = (params?: string): SWRResponse => {
    return useGetListing(`${resource}/all`, params);
}

export const useGetUser = (id: string, params?: string): SWRResponse => {
    return useGetDetail(`${resource}`, id, params);
}

export const useCreateUser = (params?: string) => {
    return useCreate(`${resource}/add`, params);
}

export const useUpdateUser = (id: string, params?: string) => {
    return useUpdate(`${resource}/update`, params);
}

export const useDeleteUser = (id: string, params?: string) => {
    return useDelete(`${resource}/delete/${id}`, params);
}

export const useGetUserProfile = (skip = true, params?: string): SWRResponse => {
    return useGetListing(!skip ? `${resource}/profile` : '', params);
}

export const useUpdateUserPassword = (id: string, params?: string) => {
    return useUpdate(`${resource}/changepassword`, params);
}
