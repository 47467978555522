import React, { useEffect, useState } from 'react';
import './scss/app.scss';
import { HashRouter, Route, RouteProps, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import { useRecoilState } from 'recoil';
import { loginState } from './states/LoginState';
import routes from './utils/Routes';
import { useCookies } from 'react-cookie';
import { useGetUserProfile } from './services/UserService';
import PublicFeedback from "./pages/PublicFeedback";
import Thankyou from "./pages/Thankyou";

const AuthenticatedLayout = React.lazy(() => import('./layouts/AuthenticatedLayout'));
const Login = React.lazy(() => import('./pages/Login'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));

function App() {
  const [cookies,] = useCookies(["jwt"]);
  const [login, setLogin] = useRecoilState(loginState);
  const [activeUser, setActiveUser] = useState(login?.user);

  const { data, error, isLoading, mutate } = useGetUserProfile(!cookies.jwt);

  useEffect(() => {
    const getUserProfile = async () => {
      const result = await mutate();
      setLogin({ user: result?.body });
      setActiveUser(result?.body);
    };

    if (!!cookies.jwt) {
      getUserProfile();
    }

  }, [cookies, setLogin, setActiveUser]);

  const isAccesable = (user?: any, routeId?: string) => {
    if (!user || !routeId) {
      return false;
    }

    let allowedRoutes = [''];
    switch (user?.role) {
      case 'SUPER_ADMINISTRATION_DEPT':
        allowedRoutes = ['*'];
        break;
      case 'IT_DEPT':
        allowedRoutes = ['*'];
        break;
      case 'MEDTECH_DEPT':
        allowedRoutes = ['edit-profile', 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'];
        break;
      case 'PATHOLOGIST_DEPT':
        allowedRoutes = ['edit-profile', 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'];
        break;
      case 'PROCUREMENT_DEPT':
        allowedRoutes = ['edit-profile'
          , 'stocks', 'stocks/edit', 'stocks/create'
          , 'invoices', 'invoices/edit', 'invoices/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'
          , 'suppliers', 'suppliers/edit', 'suppliers/create'
          , 'categories', 'categories/edit', 'categories/create'
          , 'products', 'products/edit', 'products/create'
          , 'report'];
        break;
      case 'PHYSICIAN_DEPT':
        allowedRoutes = ['edit-profile', 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'];
        break;
      case 'ADMINISTRATION_DEPT':
        allowedRoutes = ['edit-profile'
          , 'patients', 'patients/edit', 'patients/create'
          , 'billings', 'billings/view', 'billings/create'
          , 'billingshistory', 'billingshistory/view', 'billingshistory/create'
          , 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'
          , 'referrals', 'referrals/edit', 'referrals/create'
          , 'report'];
        break;
      case 'OPERATION_DEPT':
        allowedRoutes = ['edit-profile', 'patients', 'patients/edit', 'patients/create'
          , 'billings', 'billings/view', 'billings/create'
          , 'billingshistory', 'billingshistory/view', 'billingshistory/create'
          , 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'
          , 'referrals', 'referrals/edit', 'referrals/create'
          , 'report'];
        break;
      case 'RADIOLOGY_DEPT':
        allowedRoutes = ['edit-profile', 'laboratoryTestResults', 'laboratoryTestResults/edit', 'laboratoryTestResults/create'
          , 'laboratoryTestResultsHistory', 'laboratoryTestResultsHistory/edit', 'laboratoryTestResultsHistory/create'
          , 'incidents', 'incidents/edit', 'incidents/create'
          , 'feedbacks', 'feedbacks/edit', 'feedbacks/create'];
        break;
      case 'ACCOUNTING_DEPT':
        allowedRoutes = ['edit-profile', 'report'
          , 'stocks', 'stocks/edit', 'stocks/create'
          , 'invoices', 'invoices/edit', 'invoices/create'
        ];
        break;
      default:
        allowedRoutes = [''];
    }

    return allowedRoutes.includes('*') || (allowedRoutes && allowedRoutes.includes(routeId));
  }

  return (
    <React.Fragment>
      <React.Suspense>
        <HashRouter>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/publicfeedback' element={<PublicFeedback />} />
            <Route path='/thankyou' element={<Thankyou />} />
            <Route path="/" element={
              <PrivateRoute isLoggedIn={!!cookies.jwt}>
                <AuthenticatedLayout user={activeUser} />
              </PrivateRoute>
            }>
              <Route index element={<Dashboard />} />
              <Route path='dashboard' element={<Dashboard />} />
              {routes.map((route: RouteProps) => {
                return route.element && isAccesable(activeUser, route.id)
                  ? (<Route key={route.id} {...route} />)
                  : (null)
              })}
            </Route>
          </Routes>
        </HashRouter>
      </React.Suspense>
    </React.Fragment>
  );
}

export default App;
