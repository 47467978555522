import { AllowEditLaboratoryTypes } from '../enum';

export const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const base64toBlob = (base64Data: string) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const openBase64NewTab = (base64Pdf: string): void => {
  var blob = base64toBlob(base64Pdf);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

export const handlePrintOnClick = async (trigger: any, payload = {}) => {
  const result = await trigger(payload);
  if (result?.body.base64Pdf) {
    openBase64NewTab(result?.body.base64Pdf);
  }
};

export const numberFormat = (value: number) =>
  new Intl.NumberFormat('en-US').format(value);

export const isAllowedEditLaboratoryType = (
  laboratoryType: string
): laboratoryType is AllowEditLaboratoryTypes => {
  return laboratoryType in AllowEditLaboratoryTypes;
};

/**
 * 
 * @param birthdate '1990-01-20'
 * @returns number
 */
export const calcAgeInYears = (birthdate: string) => {
  const today = new Date(),
    birthDate = new Date(birthdate),
    m = today.getMonth() - birthDate.getMonth();

  let age = today.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
