import React, {useEffect, useState} from 'react'
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CRow,
} from '@coreui/react'
import {FormProvider, useForm} from 'react-hook-form'
import {useRecoilState} from 'recoil'
import {loginState} from '../states/LoginState'
import {useGetBranches} from "../services/BranchService";
import {useCreateFeedback} from "../services/FeedbackService";
import {useNavigate} from "react-router-dom";
import BDL from '../images/logo_old.png';

const PublicFeedback = () => {
    const {trigger, error, isMutating, data} = useCreateFeedback();
    const {data: responseBranches, error: errorBranches, isLoading: isLoadingBranches} = useGetBranches();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [, setLogin] = useRecoilState(loginState);

    const methods = useForm<any>({
        defaultValues: {
            feedback: ""
        }
    });

    const {
        control, register, handleSubmit, setValue, reset,
        formState: {errors, isSubmitSuccessful, isDirty, dirtyFields}
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            const result = await trigger(data);
            navigate('/thankyou');
            reset();
        } catch (error) {
            console.log('error', error);
        }
    }

    const imagePath = BDL;

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <FormProvider {...methods}>
                                        <CForm onSubmit={handleSubmit(onSubmit)}>
                                            {(!isMutating && error) && (
                                                <CAlert color="danger" dismissible>
                                                    {errorMessage ?? 'Something went wrong. Please try again or contact us.'}
                                                </CAlert>
                                            )}

                                            {(isMutating && !error) && (
                                                <CAlert color="success" dismissible>
                                                    {successMessage ?? 'Data has been successfully created.'}
                                                </CAlert>
                                            )}

                                            <h1><img src={imagePath} alt="Logo"
                                                     style={{
                                                         width: '100px', // Set the width as desired
                                                         height: '100px', // Set the height as desired
                                                     }}/> Blesces Diagnostic Laboratory
                                            </h1>
                                            &nbsp;
                                            <h3>Customer Service Feedback</h3>
                                            <CRow>
                                                <CCol md={12} className='mt-3'>
                                                    <CRow>
                                                        <CCol xs={6}>
                                                            <CFormLabel htmlFor="branch">Branch</CFormLabel>
                                                            <CFormSelect  {...register('branch', {required: true})}
                                                                          required options={
                                                                [
                                                                    {label: 'Select Branch', value: ""},
                                                                    ...responseBranches?.body?.map((item: any) => ({
                                                                        label: item.name,
                                                                        value: item.name
                                                                    })) ?? []
                                                                ]
                                                            }/>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={12}>
                                                    <CRow>
                                                        <CCol xs={12} className='mt-3'>
                                                            <CFormLabel htmlFor="feedback">Feedback</CFormLabel>
                                                            <CFormTextarea
                                                                rows={12} {...register('feedback', {required: true})}
                                                                required/>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol xs={6} className='mt-3'>
                                                    <CButton type='submit' color="primary"
                                                             className="px-4 cil-align-right">
                                                        Submit
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                            <p className="text-medium-emphasis mt-3">Disclaimer: Your personal information will remain entirely confidential.</p>
                                        </CForm>
                                    </FormProvider>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
}

export default PublicFeedback;
