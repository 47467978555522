import { fetcher, poster, updater, deleter, posterFile } from '../bootstrap';
import { SWRResponse } from 'swr';
import { config } from '../config';
import useSWRMutation from 'swr/mutation';
import useSWRImmutable from 'swr/immutable';

export const useGetData = (path: string, params?: string): SWRResponse => {
    return useSWRImmutable(
        path ? `${config?.API_HOST}/${path}` + (params ? `?${params}` : '') : null,
        fetcher
    );
}

export const useGetListing = (path: string, params?: string): SWRResponse => {
    return useGetData(path, params);
}

export const useGetDetail = (path: string, id: string, params?: string): SWRResponse => {
    return useGetData(id ? `${path}/${id}` : '', params);
}

export const useCreate = (path: string, params?: string) => {
    return useSWRMutation(
        `${config?.API_HOST}/${path}` + (params ? `?${params}` : ''),
        poster
    );
}

export const useUpdate = (path: string, params?: string) => {
    return useSWRMutation(
        `${config?.API_HOST}/${path}` + (params ? `?${params}` : ''),
        updater
    );
}

export const useDelete = (path: string, params?: string) => {
    return useSWRMutation(
        `${config?.API_HOST}/${path}` + (params ? `?${params}` : ''),
        deleter
    );
}

export const useCreateFile = (path: string, params?: string) => {
    return useSWRMutation(
        `${config?.API_HOST}/${path}` + (params ? `?${params}` : ''),
        posterFile
    );
}