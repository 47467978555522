import React from "react";
import { RouteProps } from "react-router-dom";

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const Users = React.lazy(() => import('../views/Users/Users'));
const EditUser = React.lazy(() => import('../views/Users/Edit'));
const CreateUser = React.lazy(() => import('../views/Users/Create'));
const Patients = React.lazy(() => import('../views/Patients/Patients'));
const EditPatient = React.lazy(() => import('../views/Patients/Edit'));
const CreatePatient = React.lazy(() => import('../views/Patients/Create'));
const Branches = React.lazy(() => import('../views/Branches/Branches'));
const EditBranch = React.lazy(() => import('../views/Branches/Edit'));
const CreateBranch = React.lazy(() => import('../views/Branches/Create'));
const LaboratoryTypes = React.lazy(() => import('../views/LaboratoryTypes/LaboratoryTypes'));
const EditLaboratoryType = React.lazy(() => import('../views/LaboratoryTypes/Edit'));
const CreateLaboratoryType = React.lazy(() => import('../views/LaboratoryTypes/Create'));
const LaboratoryTests = React.lazy(() => import('../views/LaboratoryTests/LaboratoryTests'));
const EditLaboratoryTest = React.lazy(() => import('../views/LaboratoryTests/Edit'));
const CreateLaboratoryTest = React.lazy(() => import('../views/LaboratoryTests/Create'));
const Companies = React.lazy(() => import('../views/Companies/Companies'));
const EditCompany = React.lazy(() => import('../views/Companies/Edit'));
const CreateCompany = React.lazy(() => import('../views/Companies/Create'));
const Discounts = React.lazy(() => import('../views/Discounts/Discounts'));
const EditDiscount = React.lazy(() => import('../views/Discounts/Edit'));
const CreateDiscount = React.lazy(() => import('../views/Discounts/Create'));
const Feedbacks = React.lazy(() => import('../views/Feedbacks/Feedbacks'));
const EditFeedback = React.lazy(() => import('../views/Feedbacks/Edit'));
const CreateFeedback = React.lazy(() => import('../views/Feedbacks/Create'));
const Incidents = React.lazy(() => import('../views/Incidents/Incidents'));
const EditIncident = React.lazy(() => import('../views/Incidents/Edit'));
const CreateIncident = React.lazy(() => import('../views/Incidents/Create'));
const Billings = React.lazy(() => import('../views/Billings/Billings'));
const CreateBilling = React.lazy(() => import('../views/Billings/Create'));
const ViewBilling = React.lazy(() => import('../views/Billings/View'));
const Suppliers = React.lazy(() => import('../views/Suppliers/Suppliers'));
const EditSupplier = React.lazy(() => import('../views/Suppliers/Edit'));
const CreateSupplier = React.lazy(() => import('../views/Suppliers/Create'));
const Categories = React.lazy(() => import('../views/Categories/Categories'));
const EditCategories = React.lazy(() => import('../views/Categories/Edit'));
const CreateCategories = React.lazy(() => import('../views/Categories/Create'));
const Products = React.lazy(() => import('../views/Products/Products'));
const EditProducts = React.lazy(() => import('../views/Products/Edit'));
const CreateProducts = React.lazy(() => import('../views/Products/Create'));
const Stocks = React.lazy(() => import('../views/Stocks/Stocks'));
const EditStock = React.lazy(() => import('../views/Stocks/Edit'));
const CreateStock = React.lazy(() => import('../views/Stocks/Create'));
const Invoices = React.lazy(() => import('../views/Invoices/Invoices'));
const EditInvoice = React.lazy(() => import('../views/Invoices/Edit'));
const CreateInvoice = React.lazy(() => import('../views/Invoices/Create'));
const LaboratoryTestResults = React.lazy(() => import('../views/LaboratoryTestResults/LaboratoryTestResults'));
const ViewTestResults = React.lazy(() => import('../views/LaboratoryTestResults/View'));
const CreateLaboratoryTestResults = React.lazy(() => import('../views/LaboratoryTestResults/Create'));
const Report = React.lazy(() => import('../views/Reports/Report'));
const EditProfile = React.lazy(() => import('../views/Profile/Edit'));
const BillingsHistory = React.lazy(() => import('../views/HistoryBillings/HistoryBillings'));
const CreateBillingHistory = React.lazy(() => import('../views/HistoryBillings/Create'));
const ViewBillingHistory = React.lazy(() => import('../views/HistoryBillings/View'));
const LaboratoryTestResultsHistory = React.lazy(() => import('../views/LaboratoryTestResultsHistory/LaboratoryTestResults'));
const ViewTestResultsHistory = React.lazy(() => import('../views/LaboratoryTestResultsHistory/View'));
const CreateLaboratoryTestResultsHistory = React.lazy(() => import('../views/LaboratoryTestResultsHistory/Create'));
const Referrals = React.lazy(() => import('../views/Referrals/Referrals'));
const EditReferral = React.lazy(() => import('../views/Referrals/Edit'));
const CreateReferral = React.lazy(() => import('../views/Referrals/Create'));

const routes: RouteProps[] = [
    { id: 'dashboard', element: <Dashboard />, path: '/dashboard' },
    { id: 'edit-profile', element: <EditProfile />, path: '/edit-profile' },
    { id: 'users', element: <Users />, path: '/users' },
    { id: 'users/edit', element: <EditUser />, path: '/users/:userId' },
    { id: 'users/create', element: <CreateUser />, path: '/users/create' },
    { id: 'patients', element: <Patients />, path: '/patients' },
    { id: 'patients/edit', element: <EditPatient />, path: '/patients/:patientId' },
    { id: 'patients/create', element: <CreatePatient />, path: '/patients/create' },
    { id: 'branches', element: <Branches />, path: '/branches' },
    { id: 'branches/edit', element: <EditBranch />, path: '/branches/:branchId' },
    { id: 'branches/create', element: <CreateBranch />, path: '/branches/create' },
    { id: 'discounts', element: <Discounts />, path: '/discounts' },
    { id: 'discounts/edit', element: <EditDiscount />, path: '/discounts/:discountId' },
    { id: 'discounts/create', element: <CreateDiscount />, path: '/discounts/create' },
    { id: 'laboratoryTypes', element: <LaboratoryTypes />, path: '/laboratory-types' },
    { id: 'laboratoryTypes/edit', element: <EditLaboratoryType />, path: '/laboratory-types/:laboratoryTypeId' },
    { id: 'laboratoryTypes/create', element: <CreateLaboratoryType />, path: '/laboratory-types/create' },
    { id: 'laboratoryTests', element: <LaboratoryTests />, path: '/laboratory-tests' },
    { id: 'laboratoryTests/edit', element: <EditLaboratoryTest />, path: '/laboratory-tests/:laboratoryTestId' },
    { id: 'laboratoryTests/create', element: <CreateLaboratoryTest />, path: '/laboratory-tests/create' },
    { id: 'companies', element: <Companies />, path: '/companies' },
    { id: 'companies/edit', element: <EditCompany />, path: '/companies/:companyId' },
    { id: 'companies/create', element: <CreateCompany />, path: '/companies/create' },
    { id: 'feedbacks', element: <Feedbacks />, path: '/feedbacks' },
    { id: 'feedbacks/edit', element: <EditFeedback />, path: '/feedbacks/:feedbackId' },
    { id: 'feedbacks/create', element: <CreateFeedback />, path: '/feedbacks/create' },
    { id: 'incidents', element: <Incidents />, path: '/incidents' },
    { id: 'incidents/edit', element: <EditIncident />, path: '/incidents/:incidentId' },
    { id: 'incidents/create', element: <CreateIncident />, path: '/incidents/create' },
    { id: 'billings', element: <Billings />, path: '/billings' },
    { id: 'billings/create', element: <CreateBilling />, path: '/billings/create/:patientId?' },
    { id: 'billings/view', element: <ViewBilling />, path: '/billings/:billingId' },
    { id: 'suppliers', element: <Suppliers />, path: '/suppliers' },
    { id: 'suppliers/edit', element: <EditSupplier />, path: '/suppliers/:supplierId' },
    { id: 'suppliers/create', element: <CreateSupplier />, path: '/suppliers/create' },
    { id: 'categories', element: <Categories />, path: '/categories' },
    { id: 'categories/edit', element: <EditCategories />, path: '/categories/:categoryId' },
    { id: 'categories/create', element: <CreateCategories />, path: '/categories/create' },
    { id: 'products', element: <Products />, path: '/products' },
    { id: 'products/edit', element: <EditProducts />, path: '/products/:productId' },
    { id: 'products/create', element: <CreateProducts />, path: '/products/create' },
    { id: 'laboratoryTestResults', element: <LaboratoryTestResults />, path: '/laboratory-test-results' },
    { id: 'laboratoryTestResults/edit', element: <ViewTestResults />, path: '/laboratory-test-results/:transactionId' },
    { id: 'laboratoryTestResults/create', element: <CreateLaboratoryTestResults />, path: '/laboratory-test-results/create' },
    { id: 'stocks', element: <Stocks />, path: '/stocks' },
    { id: 'stocks/edit', element: <EditStock />, path: '/stocks/:purchaseId' },
    { id: 'stocks/create', element: <CreateStock/>, path: '/stocks/create' },
    { id: 'invoices', element: <Invoices />, path: '/invoices' },
    { id: 'invoices/edit', element: <EditInvoice />, path: '/invoices/:invoiceId' },
    { id: 'invoices/create', element: <CreateInvoice />, path: '/invoices/create' },
    { id: 'referrals', element: <Referrals />, path: '/referrals' },
    { id: 'referrals/edit', element: <EditReferral />, path: '/referrals/:referralId' },
    { id: 'referrals/create', element: <CreateReferral />, path: '/referrals/create' },
    { id: 'report', element: <Report />, path: '/report' },
    { id: 'billingshistory', element: <BillingsHistory />, path: '/billingshistory' },
    { id: 'billingshistory/create', element: <CreateBillingHistory />, path: '/billingshistory/create' },
    { id: 'billingshistory/view', element: <ViewBillingHistory />, path: '/billingshistory/:billingId' },
    { id: 'laboratoryTestResultsHistory', element: <LaboratoryTestResultsHistory />, path: '/laboratory-test-results-history' },
    { id: 'laboratoryTestResultsHistory/edit', element: <ViewTestResultsHistory />, path: '/laboratory-test-results-history/:transactionId' },
    { id: 'laboratoryTestResultsHistory/create', element: <CreateLaboratoryTestResultsHistory />, path: '/laboratory-test-results-history/create' },


];

export default routes;