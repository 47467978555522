import React from 'react';
import { redirect, RouteProps, Navigate } from 'react-router-dom';

type IPrivateRouteProps = {
    isLoggedIn: boolean;
}

const PrivateRoute = ({ isLoggedIn, children }: IPrivateRouteProps & any) => {
    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    return children;
}

export default PrivateRoute;