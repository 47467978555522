import React from 'react'
import {CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow,} from '@coreui/react'
import BDL from '../images/logo_old.png';

const PublicFeedback = () => {

    const imagePath = BDL;

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1><img src={imagePath} alt="Logo"
                                                 style={{
                                                     width: '100px', // Set the width as desired
                                                     height: '100px', // Set the height as desired
                                                 }}/> Blesces Diagnostic Laboratory
                                        </h1>
                                        &nbsp;
                                        <h3>Thank you for your Feedback!</h3>
                                        <p className="text-medium-emphasis mt-3">You can now close the browser or tab.</p>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
}

export default PublicFeedback;
